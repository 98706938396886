import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './App.css';

const predefinedTags = ["Movies", "Poetry", "Love", "Friendship", "Zeherila Jokes"];

function App() {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');

  const handleSearch = () => {
    const dummyResponse = `YeaH, I understand your question  "${query}". But I am still learning, and in between my system is crashing.So thanks for your patience and eagerness, I will comeback once my power is up.`;
    setResponse(dummyResponse);
  };

  const handleTagClick = (tag) => {
    setQuery(tag);
    handleSearch();
  };

  return (
    <Container className="app-container">
      {/* "Site Under Construction" Banner */}
      <Box className="under-construction">
              Site Under Construction
            </Box>

      {/* Header with Logo and Title */}
      <Box className="header" sx={{ textAlign: 'center', mt: 5, mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src="favicon.ico" alt="Logo" className="logo" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
        <Typography variant="h4" gutterBottom>
          seenu-ai
        </Typography>
      </Box>

      {/* Redesigned Prompt Input */}
      <Box className="prompt-container" sx={{ textAlign: 'center', mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TextField
          placeholder="Type your question here..."
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          sx={{
            width: '60%',
            mr: 1,
            borderRadius: '50px',
            backgroundColor: '#fff',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            sx: {
              borderRadius: '50px',
              paddingLeft: '10px',
            },
          }}
        />
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{
            padding: '10px 20px',
            fontSize: '1.2rem',
            borderRadius: '50px',
            backgroundColor: '#1976d2',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#115293',
            },
          }}
        >
          Ask
        </Button>
      </Box>

      {/* Predefined Tags */}
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Typography variant="subtitle1">Popular Topics:</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 1 }}>
          {predefinedTags.map((tag, index) => (
            <Button
              key={index}
              variant="outlined"
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </Button>
          ))}
        </Box>
      </Box>

      {/* Conditionally Rendered Response Section */}
      {response && (
        <Box className="response-section" sx={{ mt: 4, p: 3, borderRadius: '12px', backgroundColor: '#f7f8fa', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', maxWidth: '90%', margin: '0 auto', minHeight: '100px' }}>
          <Typography variant="body1" sx={{ fontSize: '1.1rem', color: '#333', lineHeight: 1.6 }}>
            {response}
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default App;
